<template>
  <div>
    <category-ranking v-if="currentApp.id" :key="currentApp.id" :app-data="{id: currentApp.id}" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CategoryRanking from "@/components/AppDetailsComponent/components/CategoryRanking/index.vue";

export default {
  name: "DashboardCategoryRanking",
  components: {
    CategoryRanking,
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
  },
}
</script>
